<template>
  <div class="page">
    <div class="mobile-block">
      <ECServices />
      <ECCreateAccount v-if="!$store.state.user.token"/>
    </div>

    <div class="page-title">Оказываем услуги в сфере обращения с отходами</div>

    <div class="services-list">
      <router-link
        v-for="service in servicesList"
        :key="service.path"
        :to="service.path"
        class="services-item"
      >
        <div class="row">
          <img :src="require(`@/assets/images/services-${service.path}.svg`)">
          <img src="@/assets/images/arrow-sm.svg" alt="">
        </div>
        <span v-html="service.label"/>
      </router-link>

      <a
        href="https://shop.renlife.ru/products/smart-plus-pampadu?agNum=85110&addAgNum=1012081&utm_source=pampadu&sub_id=220512&agBrNum=220512&click_id=8054cd07-dcda-47e2-b237-fa20e6080675&utm_campaign=220512"
        target="_blank"
        class="services-item banner-image"
      >
      </a>
    </div>

    <ECGosuslugiWidget />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import ECServices from '@/components/ECServices.vue'
import ECCreateAccount from '@/components/ECCreateAccount.vue'
import ECGosuslugiWidget from '@/components/ECGosuslugiWidget.vue'

const servicesList = ref([
  {
    path: 'contract-tko',
    label: 'Заключить <br/> договор на вывоз <br/> ТКО'
  },
  {
    path: 'rules-tko',
    label: 'Правила обращения с ТКО'
  },
  {
    path: 'secondary-resources',
    label: 'Прием вторичных ресурсов'
  },
  {
    path: 'no-tko-service',
    label: 'Вывоз отходов, <br/> не относящихся к ТКО'
  },
  {
    path: 'faq',
    label: 'Часто <br/> задаваемые <br/> вопросы'
  }
])
</script>

<style scoped lang="scss">
.page{
  padding-top: 40px;
  @media (max-width: 740px){
    padding-top: 0
  }
}
.mobile-block{
  display: none;
  @media (max-width: 740px){
    display: block;
    margin-bottom: 30px;
  }
}
.page-title{
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.02em;
  padding-left: 0;
  margin-bottom: 64px;

  @media (max-width: 1100px){
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.02em;
  }
}
.page-subtitle{
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 70px;
  line-height: 160%;
  @media (max-width: 1100px){
    margin-bottom: 40px;
  }
}

.services-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1100px){
    margin-top: 25px;
  }
}

.services-item{
  width: calc(50% - 12px);
  height: 188px;
  border-radius: 24px;
  background: white;
  padding: 28px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  cursor: pointer;
  span{
    font-size: 24px;
    line-height: 24px;
    margin-top: 23px;
  }
  .row{
    display: flex;
    justify-content: space-between;
    img{
      transition: ease .3s;
      &:last-child{
        width: 35px;
      }
    }

  }
  &.banner-image{
    background-image: url('~@/assets/images/smart3.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
  &:hover{
    .row{
      img{
        &:last-child{
          transform: translateX(10px)
        }
      }
    }
  }
  @media (max-width: 1100px){
    padding: 12px;
    height: 115px;
    border-radius: 16px;
    width: calc(50% - 8px);
    margin-bottom: 18px;

    .row{
      img{
        width: 24px;
        height: 24px;
        &:last-child {
          width: 24px;
        }
      }
    }
    span{
      margin-top: 16px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>

